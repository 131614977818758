import React from 'react';
import './MealInstructions.css';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
// Importing images for each step
import step1Img from './files/dinner1.jpeg'; // Replace with your actual image paths
import step2Img from './files/dinner2.jpeg';
import step3Img from './files/dinner3.jpeg';



const MealInstructions = () => {
  const { t } = useTranslation();

  return (
    <div className='panel'>
    <div className="meal-instructions">
      <h2 className='h1'>{t('instruction')}</h2>
      <ol>
        <li>
          <img src={step1Img} alt={t('cameraPosition')} /><br></br>
          {t('positionCamera')}
          <ol>
            <li>{t('adjustCameraHeight')}</li>
            <li>{t('frameMealProperly')}</li>
          </ol>
        </li>
        <li>
          <img src={step2Img} alt={t('checkIngredients')} /><br></br>
          {t('checkIngredients')}
          <ol>
            <li>{t('allIngredientsVisible')}</li>
            <li>{t('removeObstacles')}</li>
          </ol>
        </li>
        <li>
          <img src={step3Img} alt={t('clickButton')} /><br></br>
          {t('clickButton')}
          <ol>
            <li>{t('pressShutterButton')}</li>
            <li>{t('steadyHand')}</li>
          </ol>
        </li>
        <li>
          {t('waitForAnalysis')}<br></br>
          <ol>
            <li>{t('waitForProcessing')}</li>
            <li>{t('patienceRequired')}</li>
          </ol>
        </li>
        <li>
          {t('checkNutritionalSummary')}<br></br>
          <ol>
            <li>{t('viewNutritionalInfo')}</li>
            <li>{t('makeAdjustmentsIfNecessary')}</li>
          </ol>
        </li>
      </ol>

      {/* Dodajemy przycisk "Wszystko wiem" */}
      <Link className="acknowledge-button" to="/app/analyze">
        {t('allSet')} {/* Przycisk będzie wyświetlać tekst "Wszystko wiem" */}
      </Link>
    </div>
    </div>
  );
};

export default MealInstructions;
