// Media.js
import React from 'react';
import { useTranslation } from 'react-i18next'; 
import { Link } from 'react-router-dom';
import './Details.css';

const Media = () => {
  const { t } = useTranslation();

  return (
    <section className="media-container fade-in">
      <hr></hr>
      <h1 className="media-title"><span className="dc">DiabCalc</span> {t('inmedia')}</h1>
      <p className='details-vc-description'>{t('mediadesc')}</p>

      <div className="media-links">

        <div className="media-card">
          <Link to="https://www.androidowy.pl/diabcalc-aplikacja-dla-osob-z-cukrzyca/" className="media-link">
            <div className="media-image androidowy"></div>
            <div className="media-text">Androidowy.pl</div>
          </Link>
        </div>
        <div className="media-card mojacukrzyca">
          <Link to="https://www.mojacukrzyca.org/" className="media-link">
            <div className="media-image mojacukrzyca"></div>
            <div className="media-text">MojaCukrzyca.org</div>
          </Link>
        </div>
        {/* Add more media cards here if needed */}
      </div>
     



    </section>
  );
};

export default Media;
