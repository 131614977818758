import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const RedirectToNoWWW = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const host = window.location.host;
    if (host.startsWith('www.')) {
      window.location.replace("https://diabcalc.com/");
    }
  }, [navigate]);

  return null; // Komponent nie renderuje nic, działa tylko jako przekierowanie
};

export default RedirectToNoWWW;
