import React, { useState, useRef, useEffect } from 'react';
import Webcam from 'react-webcam';
import './AddProductUser.css';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useUser } from './UserContext';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getApiUrl } from './apiUrl';

const AddProductUser = () => {
  const [step, setStep] = useState(1); // Current step in the process
  const [photos, setPhotos] = useState([]); // Product photos
  const [isPanelVisible, setPanelVisible] = useState(true);
  const [photoDesc, setPhotoDesc] = useState('');
  const [descId, setDescId] = useState(1);
  const { t } = useTranslation(); 
  const { user } = useUser();
  const navigate = useNavigate();
  const [selectedPhoto, setSelectedPhoto] = useState(null); // Photo to display in modal
  const apiUrl = getApiUrl();

  // Update photo description on step or descId change
  useEffect(() => {
    if (step === 2) {
      setPhotoDesc(t(`photoDesc${descId}`));
    }
  }, [step, descId, t]);

  // Redirect to login if user is not authenticated
  useEffect(() => {  
    if (!user) {
      toast.error(t('mustBeLoggedInToAddProduct'));
      navigate('/login');
    }
  }, [user, t, navigate]);

  const webcamRef = useRef(null);
  const videoConstraints = {
    width: { min: window.innerHeight },
    height: { min: window.innerWidth },
    facingMode: 'environment', // Rear camera
  };

  // Capture photo using webcam
  const capture = async () => {
    try {
      const imageSrc = webcamRef.current?.getScreenshot();
      if (!imageSrc) throw new Error('Could not capture the photo');

      setPhotos(prevPhotos => [...prevPhotos, imageSrc]);

      if (photos.length >= 2) handleNextStep();

      setPanelVisible(false);
      setTimeout(() => setPanelVisible(true), 500);

      setDescId(prevId => prevId + 1);
      toast.success(t('photoCaptured'));
    } catch (error) {
      toast.error(error.message || t('errorCapturingPhoto'));
    }
  };

  const handleNextStep = () => setStep(prevStep => prevStep + 1);

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const sendPhotosToServer = async () => {
    try {
      const uploadPromises = photos.map((photo, i) => {
        const formData = new FormData();
        const blob = dataURItoBlob(photo);

        formData.append('sendfile', blob, `image_${i}.jpg`);
        return axios.post(`${apiUrl}upload`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
      });

      await Promise.all(uploadPromises);
      toast.success(t('photosUploadedSuccessfully'));
    } catch (error) {
      toast.error(t('errorUploadingFiles'));
      console.error('Error uploading photos:', error);
    }
  };

  const handlePhotoClick = (photo) => setSelectedPhoto(photo);
  const handleCloseModal = () => setSelectedPhoto(null);
  const handleSendPhotos = async () => await sendPhotosToServer();

  return (
    <div className='panel'>
      <div className="overlay"></div>
      <div className="addPanel">
        {step === 1 && (
          <>
            <h1>{t('timeToAdd')}</h1>
            <p>{t('prepare')}</p>
            <p>{t('photosInfo')}</p>
            <button className='button' onClick={handleNextStep}>{t('next')}</button>
          </>
        )}
      </div>

      {step === 2 && (
        <>
          <div className="addPanel Up" style={{ opacity: isPanelVisible ? 1 : 0 }}>
            <p>{photoDesc}</p>
            <button className='button' onClick={capture}>{t('takePhoto')}</button>
          </div>
          <Webcam
            audio={false}
            ref={webcamRef}
            videoConstraints={videoConstraints}
            screenshotFormat="image/jpeg"
            className="webcam"
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
          />
        </>
      )}

      {step === 3 && (
        <>
          <div className='addPanel ThreeStep'>
            <p>{t('checkPhotos')}</p>
            <div className="preview-photos">
              {photos.map((photo, index) => (
                <img className='photo' onClick={() => handlePhotoClick(photo)} key={index} src={photo} alt={`Photo ${index + 1}`} />
              ))}
            </div>
            <button className='button' onClick={handleSendPhotos}>{t('sendPhotos')}</button>
          </div>
          {selectedPhoto && (
            <div className="modal">
              <div className="modal-content">
                <span className="close" onClick={handleCloseModal}>&times;</span>
                <img src={selectedPhoto} className="photosel" alt="Selected" />
              </div>
            </div>
          )}
        </>
      )}

      {step === 4 && (
        <>
          <div className='addPanel'> 
            <p>{t('adminAddProduct')}</p>
            <p>{t('thanks')}</p>
            <Link className="button" to="/">{t('gobacktoapp')}</Link>
          </div>
        </>
      )}
    </div>
  );
};

export default AddProductUser;
