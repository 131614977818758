import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useUser } from './UserContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import IonIcon from "@reacticons/ionicons";
import './SearchView.css';
import _ from 'lodash';
import { getApiUrl } from './apiUrl';
import noimage from "./noimage.jpg";
import Loading from './Loading';
import AddMealPPopup from './AddToMealPopup';
import ReactGA from 'react-ga4';
import BugReport from './BugReport';
import { useMeal } from './MealContext';




const SearchView = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { currentMeal } = useMeal();
  const [query, setQuery] = useState('');
  const [products, setProducts] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [error, setError] = useState(null);
  const [showSearch, setShowSearch] = useState(true);
  const [page, setPage] = useState(1); // Page state for pagination
  const [hasMore, setHasMore] = useState(true); // Flag to check if more products are available
  const apiUrl = getApiUrl();
  const isFetching = useRef(false); // Ref to prevent multiple fetches at once
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isBugReportOpen, setIsBugReportOpen] = useState(false);
  // Ref to manage scroll event listener
  const observer = useRef();
  const lastProductElementRef = useRef();
  const [isSimpleView, setIsSimpleView] = useState(true); // Dodano stan dla uproszczonego widoku
  const [hasSearched, setHasSearched] = useState(false); // Add this new state
  const [retryCount, setRetryCount] = useState(0);
  const maxRetries = 3; // Maximum number of retry attempts
  const retryDelay = 1000; // Delay between retries in milliseconds
  const [searchComplete, setSearchComplete] = useState(false); // Add this new state
  const handleOpenPopup = (product) => {
    if(user){
      setSelectedProduct(product); // Set selected product
      setIsPopupVisible(true); // Show popup
    } else {
      toast.error(t('mustBeLoggedInToAddProduct'));
      navigate('/app/login')
    }
  };
  const handleOpenPopupBug = (product) => {
    setSelectedProduct(product); // Ustawia aktualnie wybrany produkt
    setIsBugReportOpen(true); // Otwiera pop-up
  };
  const handleClosePopup = () => {
    setIsPopupVisible(false); // Zamykasz pop-up
  };
  const [loading, setLoading] = useState(false); // Dodano stan ładowania
  const [serverAvailable, setServerAvailable] = useState(true); // Dodano stan dostępności serwera
  // Dodaj funkcję do sprawdzania dostępności serwera
  const checkServerAvailability = async () => {
    setLoading(true); // Ustawienie ładowania na true
    try {
      const response = await fetch(getApiUrl()); // Użyj odpowiedniego URL
      if (!response.ok) {
        throw new Error('Server not reachable');
      }
      setServerAvailable(true); // Ustawienie dostępności serwera na true
    } catch (error) {
      setError(error.message); // Ustaw błąd
      setServerAvailable(false); // Ustawienie dostępności serwera na false
    } finally {
      setLoading(false); // Ustawienie ładowania na false
    }
  };


  useEffect(() => {
    checkServerAvailability();
    const intervalId = setInterval(() => {
      checkServerAvailability();
    }, 5000); // Sprawdzenie dostępności serwera co 5 sekund

    return () => clearInterval(intervalId); // Czyszczenie interwału po odmontowaniu komponentu
  }, []);

  const fetchMoreProducts = useCallback(async () => {
    setLoading(true); // Ustawienie ładowania na true
    if (isFetching.current) return;
    isFetching.current = true;
    ReactGA.event({
      category: 'app_use',
      action: 'search-fetchmore',
      label: 'Fetched more products',
    });
    try {
      const response = await axios.get(`${apiUrl}search`, {
        params: { query: encodeURIComponent(query), page, limit: 50 },
        withCredentials: true,
      });

      const { success, products: fetchedProducts } = response.data;

      if (success) {
        setProducts(prevProducts => [...prevProducts, ...fetchedProducts]);
        setPage(prevPage => prevPage + 1);
        setHasMore(fetchedProducts.length > 0);
      } else {
        setError(response.data.message);
        setServerAvailable(false); // Ustawienie dostępności serwera na false
      }
    } catch (error) {
      console.error(error);
      setError(error.message);
    } finally {
      isFetching.current = false;
      setLoading(false); // Ustawienie ładowania na false
    }
  }, [query, page, apiUrl]);

  useEffect(() => {
    if (lastProductElementRef.current) {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore) {
          fetchMoreProducts();
        }
      });
      observer.current.observe(lastProductElementRef.current);
    }
  }, [lastProductElementRef, hasMore, fetchMoreProducts]);

  useEffect(() => {
    const handleScroll = _.throttle(() => {
      if (window.innerHeight + document.documentElement.scrollTop + 100 >= document.documentElement.offsetHeight && hasMore && !isFetching.current) {
        fetchMoreProducts();
      }
    }, 300);

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [fetchMoreProducts, hasMore]);

  const searchProducts = async (searchQuery) => {
    // Allow spaces in search but still prevent completely empty searches
    if (!searchQuery.trim()) {
      toast.info(t('enterSearchQuery'));
      return;
    }

    ReactGA.event({
      category: 'app_use',
      action: 'search',
      label: 'Searching specific products'
    });
    
    setHasSearched(true); // Set this when search is performed
    setRetryCount(0);
    setSearchComplete(false); // Reset search complete status on new search

    setLoading(true);
    try {
      setPage(1);
      setHasMore(true);
      const response = await axios.get(`${apiUrl}search`, {
        params: { 
          query: encodeURIComponent(searchQuery), // Remove trim() to allow spaces
          page: 1, 
          limit: 50 
        },
        withCredentials: true,
      });

      const { success, products: fetchedProducts } = response.data;

      if (success) {
        setProducts(fetchedProducts);
        // Only hide search if there's a specific query
        if (searchQuery) {
          setShowSearch(false);
        }
        setPage(2);
        setHasMore(fetchedProducts.length > 0);
        scrollToTop();
        setSearchComplete(true); // Set search as complete
        
        if (fetchedProducts.length === 0) {
          toast.info(t('noProductsFound'));
        }
      } else {
        setError(response.data.message);
        setSearchComplete(true); // Set search as complete even on error
      }
    } catch (error) {
      console.error(error);
      setError(error.message);
      setSearchComplete(true); // Set search as complete on error
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setQuery(value); // Remove trim() to allow spaces while typing
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      searchProducts(query);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion.product_name);
    setSuggestions([]);
    searchProducts(suggestion.product_name);
  };

  const calculateCU = (carbohydrates) => {
    return carbohydrates ? parseFloat((carbohydrates / 10).toFixed(1)) : null;
  };

  const calculateFPU = (protein,fat) => {
    let multipliedProtein = protein * 4;
    let multipliedFat = fat * 9;
    return parseFloat((multipliedProtein + multipliedFat) / 100).toFixed(1);
  };
  const calculatePerServing = (per100g, servingSize) => {
    const servingSizeInGrams = parseFloat(servingSize);
    if (!isNaN(servingSizeInGrams) && per100g !== null) {
      return (per100g * servingSizeInGrams) / 100;
    }
    return null;
  };

  const formatNumber = (number) => {
    if (typeof number === 'number' && isFinite(number)) {
      return number.toFixed(1);
    } else {
      return t('noInformation');
    }
  };

  const generateRandomNumber = () => Math.floor(Math.random() * 1000000);

  const scrollToTop = () => {
    
    var element = document.querySelector('.product-list'); // Wybierz swój element
    if(element !== null){
      element.scrollTo({ top: 0, behavior: 'smooth' });
    }
    
  };

  
  const addToMeal = async (user, product) => {
    
    ReactGA.event({
      category: 'app_use',
      action: 'addmeal',
      label: ' Added to meal',
    });
    if (!user) {
      toast.error(t('mustBeLoggedInToAddProduct'));
      navigate('/app/login');
      return;
    }

    try {
      const productId = generateRandomNumber();

      const response = await axios.post(
        `${apiUrl}addProductToList`,
        {
          userId: user.userId,
          productId: productId,
          productJson: JSON.stringify({
            product_name: product.product_name || product.name,
            barcode: product.barcode || '',
            nutriments: {
              carbohydrates_100g: product.carbohydrates_per100 || product.nutriments?.carbohydrates_100g || product.nutriments?.carbohydrates || 0,
              proteins_100g: product.protein_per100 || product.nutriments?.proteins_100g || product.nutriments?.proteins || 0,
              fats_100g: product.fat_per100 || product.nutriments?.fats_100g || product.nutriments?.fat || 0,
            },
          }),
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
          withCredentials: true,
        }
      );

      const { success, message } = response.data;

      if (success) {
        toast.success(`${t('saved')} ${product.product_name || product.name} ${t('saved2')}`);
      } else {
        toast.error(`${t('errorOccurred')}: ${message}`);
      }
    } catch (error) {
      console.error(error);
      toast.error(t('errorSaving'));
    }
  };
  
  const addProductToMeal = async (user, product, mealId) => {
    // ...existing code...
    try {
      // Your existing code for adding product
      // After successful addition:
      navigate(`/app/meal/${mealId}`);
    } catch (error) {
      console.error(error);
      toast.error(t('errorSaving'));
    }
  };


  useEffect(() => {
    // Usunięto logowanie wybranego produktu
  }, [isBugReportOpen, selectedProduct]);

  useEffect(() => {
    // Usunięto logowanie wybranego produktu
  }, [selectedProduct]);

  const handleMoreInfoClick = (product) => {
    setSelectedProduct(product); // Ustawia aktualnie wybrany produkt
    setIsPopupVisible(true); // Otwiera pop-up
  };

  const renderSimpleView = (product, index) => (
    <div 
      key={index}
      className="simple-card pop-up"
      ref={products.length === index + 1 ? lastProductElementRef : null}
    >
      {product.is_gluten_free !== undefined && (
        <div className={`gluten-badge ${product.is_gluten_free ? 'gluten-free' : 'contains-gluten'}`}>
          <IonIcon name={product.is_gluten_free ? "checkmark-circle-outline" : "close-circle-outline"} />
          {product.is_gluten_free ? t('glutenFree') : t('containsGluten')}
        </div>
      )}
      
      <img 
        src={product.image_url || noimage} 
        alt="" // Pusty alt zapobiega wyświetlaniu tekstu
        onError={(e) => {
          e.target.src = noimage; // Fallback do domyślnego obrazka
          e.target.onerror = null; // Zapobiega nieskończonej pętli
        }}
      />
      
      <h3>{product.product_name || t('noname')}</h3>
      
      <div className="nutrition-info">
        <div className="nutrition-row">
          <span>
            <IonIcon name="flash" />
            {calculateCU(product.nutriments.carbohydrates)} {t('CU')}
          </span>
          <span>na 100g</span>
        </div>
        <div className="nutrition-row">
          <span>
            <IonIcon name="flash-outline" />
            {calculateFPU(product.nutriments.proteins, product.nutriments.fat)} {t('FPU')}
          </span>
          <span>na 100g</span>
        </div>
      </div>

      <div className="actions">
        <button className="search-link" onClick={() => addToMeal(user, product)} title={t('save')}>
          <IonIcon name="bookmark-outline" />
        </button>
        <button className="search-link" onClick={() => handleOpenPopup(product)} title={t('addToMeal')}>
          <IonIcon name="restaurant-outline" />
        </button>
        <button className="search-link" onClick={() => handleOpenPopupBug(product)} title={t('reportIssue')}>
          <IonIcon name="bug-outline" />
        </button>
        {product.urlRecipe && (
          <a 
            className="search-link" 
            href={product.urlRecipe} 
            target="_blank" 
            rel="noopener noreferrer"
      
          >
            <IonIcon name="book-outline" />
          </a>
        )}
        <button 
          className="see-more-btn"
          onClick={() => navigate(`/app/product/${product._id}`, { state: { product } })}
        >
          {t('seeMore')} <IonIcon name="arrow-forward-outline" />
        </button>
      </div>

      {product.art_url && (
        <div className="recipe-badge">
          <IonIcon name="book" />
          {t('recipeAvailable')}
        </div>
      )}
    </div>
  );

  const renderDetailedView = (product, index) => (
    <div 
      key={index}
      className="detailed-card"
      ref={products.length === index + 1 ? lastProductElementRef : null}
    >
      <div className="product-header">
        <h2 className="product-name">{product.product_name || t('noname')}</h2>
        {product.urlRecipe && (
        <h3 className='recipe'>{t('recipe')}</h3>)}
        <button className='bug-report-button' onClick={() => handleOpenPopupBug(product)}>
          <IonIcon name='bug' />
        </button>
      </div>
  
      <div className="product-content">
        <div className="product-image-container">
          <img src={product?.image_url || noimage}  />
        </div>
  
        <div className="product-quick-info">
          <div className="nutrition-highlights">
            <div className="highlight-item">
              <IonIcon name="flash" />
              <span>{calculateCU(product.nutriments.carbohydrates)} {t('CU')}</span>
            </div>
            <div className="highlight-item">
              <IonIcon name="flash-outline" />
              <span>{calculateFPU(product.nutriments.proteins, product.nutriments.fat)} {t('FPU')}</span>
            </div>
          </div>
  
          <div className="action-buttons">
            <button className="primary-btn" onClick={() => addToMeal(user, product)}>
              <IonIcon name="bookmark" /> {t('save')}
            </button>
            <button className="secondary-btn" onClick={() => handleOpenPopup(product)}>
              <IonIcon name="restaurant" /> {t('addToMeal')}
            </button>
            {product.urlRecipe && (
              <a 
                className="recipe-btn" 
                href={product.urlRecipe} 
                target="_blank" 
                rel="noopener noreferrer"
              >
                <IonIcon name="book" /> {t('viewRecipe')}
              </a>
            )}
            <button 
              className="see-more-btn"
              onClick={() => navigate(`/app/product/${product._id}`, { state: { product } })}
            >
              {t('seeMore')} <IonIcon name="arrow-forward" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
        {!serverAvailable ? ( // Sprawdzenie dostępności serwera
            <Loading/> // Pełnoekranowy wskaźnik ładowania
        ) : (
            <div className='panel'>
                
                <div className='product-search'>
              
                    {showSearch ? (
                        <div className='search'> 
                         <h1 className='blue'>{t('searchdesc')}</h1>
                            <input 
                                type="text" 
                                value={query} 
                                onChange={handleInputChange} 
                                onKeyDown={handleKeyDown} 
                                aria-label={t('search')}
                                disabled={loading}
                            />
                            <button 
                                onClick={() => searchProducts(query)}
                                disabled={loading}
                            >
                                {loading ? <Loading isSmall={true} /> : t('search')}
                            </button>
                         
                        </div>
                    ) : (
                        <div className='searchclick'>
                            <button onClick={() => setShowSearch(true)} disabled={loading}>
                                <IonIcon size='50px' name="search-outline" />
                            </button>
                        </div>
                    )}

                    {/* <button 
                      className="view-toggle-button"
                      onClick={() => setIsSimpleView(!isSimpleView)}
                    >
                      {isSimpleView ? t('viewDetailed') : t('viewSimple')}
                    </button> */}
                    {hasSearched && searchComplete && products.length === 0 ? (
                        <div className='notfound'>
                            <p>{t('noProductsFound')}</p>
                        </div>
                    ) : (
                        <div className={isSimpleView ? 'simple-view' : 'product-list'}>
                            {products.map((product, index) => 
                              isSimpleView ? renderSimpleView(product, index) : renderDetailedView(product, index)
                            )}
                            {/* Add a spacer div to ensure the last product is fully visible */}
                            <div className="spacer"></div>
                        </div>
                    )}
                    {loading &&     <div className='loading'>
                                <Loading isSmall={true} />
                            </div>} {/* Wskaźnik ładowania */}
                </div>
                <AddMealPPopup
        isVisible={isPopupVisible}
        onClose={handleClosePopup}
        addProductToMeal={(product, grams, mealId) => {
          // After successful addition, navigate to meal
          if (mealId) {
            navigate(`/app/meal/${mealId}`);
          }
        }}
        user={user}
        product={selectedProduct}
        currentMealId={currentMeal?.id} // Pass current meal ID to pre-select it
      />  
       <BugReport 
         isOpen={isBugReportOpen} 
         onClose={() => setIsBugReportOpen(false)}
         isScanning={true}
         scannedBarcode={selectedProduct}
       />
            </div>
        )}
     
    </>
  );
}

export default SearchView;