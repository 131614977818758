import { openDB } from 'idb';

export const initDB = async () => {
  return openDB('MealDB', 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains('meals')) {
        db.createObjectStore('meals', { keyPath: 'userId' });
      }
    },
  });
};

export const saveMealsToDB = async (userId, meals) => {
  const db = await initDB();
  const tx = db.transaction('meals', 'readwrite');
  await tx.store.put({ userId, meals });
  await tx.done;
};

export const getMealsFromDB = async (userId) => {
  const db = await initDB();
  return await db.get('meals', userId);
};

export const clearMealsFromDB = async (userId) => {
  const db = await initDB();
  const tx = db.transaction('meals', 'readwrite');
  await tx.store.delete(userId);
  await tx.done;
};
