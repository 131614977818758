import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';
import Loading from './Loading';
import { getApiUrl } from './apiUrl.js';
const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // New loading state
  const [error, setError] = useState(null); // Nowy stan błędu
  const navigate = useNavigate();

  useEffect(() => {
 
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser) {
      setUser(storedUser);
    }
    setLoading(false); // Set loading to false once the user state is initialized
  }, []);

  // Dodaj funkcję do sprawdzania dostępności serwera
  const checkServerAvailability = async () => {
    try {
      const response = await fetch(getApiUrl()); // Użyj odpowiedniego URL
      if (!response.ok) {
        throw new Error('Server not reachable');
      }
    } catch (error) {
      setError(error.message); // Ustaw błąd
    }
  };

  // Wywołaj funkcję sprawdzającą dostępność serwera
  useEffect(() => {
    checkServerAvailability();
  }, []);

  const login = (userId, token) => {
    const user = { userId, token };
    setUser(user);
    localStorage.setItem('user', JSON.stringify(user));
  };

  const logout = () => {
    localStorage.removeItem('user');
    setUser(null);
if(!Capacitor.isNativePlatform()){
    navigate('/');
  }
  else{
    navigate('/app');
  }
  }
  if (loading) {
    // Zwróć komponent ładowania w przypadku błędu lub ładowania
    return <Loading/>;
  }

  return (
    <UserContext.Provider value={{ user, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};

