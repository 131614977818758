import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { getApiUrl } from './apiUrl';
import './NewletterSignup.css'; // Importujemy plik CSS

const NewsletterSignup = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const apiUrl = getApiUrl();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(apiUrl + 'subscribeNewsletter', { email });
      setMessage(response.data.message);
      setEmail(''); // Resetujemy pole e-mail po pomyślnym zapisie
    } catch (error) {
      setMessage(error.response?.data?.message || t('error', 'Wystąpił błąd podczas zapisywania do newslettera.'));
    }
  };

  return (
    <div className="newsletter-signup-container">
     
      <div className="ns-text-section">
        <h2 className="newsletter-title">
          {t('signupForIOSBeta', 'Zapisz się do wersji beta na iOS')}
        </h2>
        <p className="newsletter-description">
          {t(
            'newsletterDescription',
            'Zostaw nam swój adres e-mail, aby być na bieżąco i dowiedzieć się jako pierwszy, kiedy aplikacja DiabCalc będzie dostępna na iOS.'
          )}
        </p>
        <form onSubmit={handleSubmit} className="newsletter-form">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={t('enterEmail', 'Wpisz swój adres e-mail')}
            className="newsletter-input"
            required
          />
          <button type="submit" className="subscribe-button">
            {t('subscribe', 'Zapisz się')}
          </button>
        </form>
        {message && (
          <p
            className={`newsletter-message ${
              message.includes('dziękujemy') ? 'success-message' : 'error-message'
            }`}
          >
            {message}
          </p>
        )}
        <p className="privacy-note">
          {t(
            'privacyNote',
            'Twoje dane są bezpieczne. Nie udostępniamy ich osobom trzecim i wykorzystujemy je wyłącznie do informowania o nowościach dotyczących DiabCalc.'
          )}
        </p>
      </div>
    </div>
  );
};

export default NewsletterSignup;
