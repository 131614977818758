import React, { useState,useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook
import './RegisterPanel.css'; // Stworzony plik CSS dla tego komponentu
import { getApiUrl } from './apiUrl';
import { useUser } from './UserContext'; 
import IonIcon from '@reacticons/ionicons';
const RegisterPanel = () => {
  const { t } = useTranslation(); // Use the useTranslation hook
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();
  const apiUrl = getApiUrl();
  const { login } = useUser();

  useEffect(() => {
    const handleMessage = (event) => {
 

      const { success, token, userId } = event.data;

      if (success) {
        login(userId, token);  // Zapisanie danych użytkownika w stanie aplikacji
        toast.success('Login successful!');
        navigate('/app');  // Przekierowanie po udanym logowaniu
      } else {
    
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [login, navigate]);

  const handleLoginGoogle = () => {
    // Otwórz popup z Google logowaniem
    const popup = window.open(`${apiUrl}auth/google`, '_blank', 'width=500,height=600');
    
    // If the popup is blocked, give feedback to the user
    if (!popup) {
      toast.error('Popup was blocked. Please enable popups and try again.');
    }
  };

  const handleRegister = async () => {
    if (!username || !password || !confirmPassword) {
      toast.error(t('register1.missingFields'));
      return;
    }

    if (password !== confirmPassword) {
      toast.error(t('register1.passwordMismatch'));
      return;
    }

    try {
      const response = await axios.post(`${apiUrl}register`, { username, password },{
        withCredentials: true, // Ensure CORS is handled
      });

      if (response.data.success) {
        toast.success(t('register1.success'));
        navigate('/app/login');
      } else {
        toast.error(t('register1.failure'));
      }
    } catch (error) {
      console.error(error.response.data);
      toast.error(t('register1.error'));
    }
  };

  return (
    <div className='panel'>
      <div className="overlay"></div>
      <div className='modal'>
        <h1>{t('register1.title')}</h1>
        <hr />

        <label>{t('register1.email')} <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} /></label>
        <label>{t('register1.password')} <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} /></label>
        <label>{t('register1.confirmPassword')} <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} /></label>
        <button onClick={handleRegister}>{t('register1.registerButton')}</button>

        {/* <p>{t('orlogin')}</p>

<button onClick={handleLoginGoogle} className="google-login-button">
<IonIcon size="large" name="logo-google" />
{t('logingoogle')}
</button> */}

        <p>{t('register1.acceptTerms')} <Link className='change' to="/terms">{t('register1.terms')}</Link> {t('register1.and')} <Link className='change' to="/policy">{t('register1.privacyPolicy')}</Link></p>
        <p>{t('register1.alreadyHaveAccount')} <Link className='change' to="/app/login">{t('register1.login')}</Link>.</p>
      </div>
      
    </div>
  );
};

export default RegisterPanel;
