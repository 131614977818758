import React, { useState, useEffect } from 'react';
import './Home.css';
import { Link } from 'react-router-dom';
import { useUser } from './UserContext';
import { useTranslation } from 'react-i18next';
import IonIcon from '@reacticons/ionicons';
import Shortcuts from './Shortcuts';
import ShortcutPopup from './ShortcutPopup';
import { getApiUrl } from './apiUrl';
import axios from 'axios';
import WelcomeScreen from './WelcomeScreen';
const Home = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { user } = useUser();
  const { t } = useTranslation();
  const [isAdmin, setIsAdmin] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const apiUrl = getApiUrl();
  const gridRows = 4;
  const gridColumns = 4;
  const [isModerator, setIsModerator] = useState(false);


  useEffect(() => {
    checkAdminStatus();

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [user]);

  const findFreePosition = (existingPositions) => {
    for (let y = 0; y < gridRows; y++) {
      for (let x = 0; x < gridColumns; x++) {
        if (!existingPositions.some(pos => pos.x === x && pos.y === y)) {
          return { x, y };
        }
      }
    }
    return { x: 0, y: 0 };
  };

  const checkModeratorStatus = async () => {
    if (!user) {
      setIsModerator(false);
      return;
    }
  
    try {
      const headers = {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': 'application/json',
      };
  
      const response = await axios.get(`${apiUrl}checkModerator/${user.userId}`, {
        headers: headers,
      });
  
      if (response.status === 200) {
        setIsModerator(response.data.isModerator);
      } else {
        console.error(`Error checking moderator status: ${response.data.message}`);
      }
    } catch (error) {
      console.error(`Error checking moderator status: ${error.message}`);
    }
  };

  
  const checkAdminStatus = async () => {
    if (!user) {
      setIsAdmin(false);
      setIsModerator(false); // Upewnij się, że moderator jest ustawiony na false
      return;
    }
  
    try {
      const headers = {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': 'application/json',
      };
  
      const response = await axios.get(`${apiUrl}checkAdmin/${user.userId}`, {
        headers: headers,
      });
  
      if (response.status === 200) {
        setIsAdmin(response.data.isAdmin);
        await checkModeratorStatus(); // Sprawdź status moderatora
      } else {
        console.error(`Error checking admin status: ${response.data.message}`);
      }
    } catch (error) {
      console.error(`Error checking admin status: ${error.message}`);
    }
  };
  
  

  const initializeShortcuts = () => {
    const savedShortcuts = localStorage.getItem('shortcuts');
    if (savedShortcuts) {
      return JSON.parse(savedShortcuts);
    } else {
      return [
        { id: 1, label: t('scaninfo'), icon: 'scan', link: '/app/scanner', position: findFreePosition([]), mobileOnly: true },
        { id: 2, label: t('searchinfo'), icon: 'search', link: '/app/search', position: findFreePosition([{ x: 0, y: 0 }]) },
        { id: 3, label: t('ainfo'), icon: 'image', link: '/app/analyze', position: findFreePosition([{ x: 0, y: 0 }, { x: 1, y: 0 }]), mobileOnly: true },
        { id: 4, label: t('listview'), icon: 'bookmark', link: '/app/listview', position: findFreePosition([{ x: 0, y: 0 }, { x: 1, y: 0 }]) },
      ];
    }
  };

  const [shortcuts, setShortcuts] = useState(initializeShortcuts);

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleAddShortcut = (shortcut) => {
    const newPosition = findFreePosition(shortcuts.map(s => s.position));
    const newShortcut = {
      ...shortcut,
      id: shortcuts.length + 1,
      position: newPosition,
    };
    setShortcuts([...shortcuts, newShortcut]);
    setIsPopupOpen(false);
  };

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div className='panel'>
      <div className='overlay'></div>
      <div className='modal'>
      
        <h1 className='blue'>{t('welcome')}</h1>
        <p><b>{t('quicksteps')}</b></p>

        <Shortcuts
          shortcuts={shortcuts.filter(shortcut => !shortcut.mobileOnly || isMobile)}
          setShortcuts={setShortcuts}
          isEditing={isEditing}
        />

        <button onClick={handleEditToggle} className="certificate-btn secondary">
          {isEditing ? t('done') : t('edit')}
        </button>

       

        {isEditing && (
          <button className="certificate-btn secondary" onClick={handleOpenPopup}>
            {t('addButton')}
          </button>
        )}
        
<div className='admin-options'>
  {isAdmin && (
    <>
      <Link to='/app/add-product'>
        <p><IonIcon name='add-circle-outline' /> {t('addproductinfo')}</p>
      </Link>
      <Link to='/app/userslist'>
        <p><IonIcon name='people-outline' /> {t('manageUsers')}</p>
      </Link>
      <Link to='/app/adminpanel'>
        <p><IonIcon name='accessibility-outline' /> {t('reviewApplications')}</p>
      </Link>
   
    </>
  )}
  
  {isModerator && (
    <>
    {t('youareamod')}
      <Link to='/app/add-product'>
        <p><IonIcon name='add-circle-outline' /> {t('addproductinfo')}</p>
      </Link>
       <a href="https://discord.gg/RCY3uRCG5t" target="_blank" rel="noopener noreferrer">
  <p><IonIcon name='logo-discord' /> {t('joinDiscord')}</p>
</a>
    </>
  )}
</div>

      </div>
     {isPopupOpen && (
          <div className="">
            <button className="close-button" onClick={handleClosePopup}>×</button>
   
            <ShortcutPopup
              availableShortcuts={[
                { id: 5, label: t('scaninfo'), icon: 'scan', link: '/app/scanner', mobileOnly: true },
                { id: 6, label: t('searchinfo'), icon: 'search', link: '/app/search' },
                { id: 7, label: t('ainfo'), icon: 'image', link: '/app/analyze', mobileOnly: true },
                { id: 8, label: t('listview'), icon: 'bookmark', link: '/app/listview' },
                { id: 9, label: t('mealTitle'), icon: 'restaurant', link: '/app/meals' },
              ].filter(shortcut => !shortcut.mobileOnly || isMobile)}
              onAddShortcut={handleAddShortcut}
              onClose={handleClosePopup}
            />
          </div>
        )}
    </div>
    
  );
};

export default Home;
