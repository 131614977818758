import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Settings.css';
import { toast } from 'react-toastify';
import { useUser } from './UserContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from './i18n';
import { clearMealsFromDB } from './indexedDB'; // Import the function to clear data from IndexedDB
import { Capacitor } from '@capacitor/core';
import BugReport from './BugReport';


const Settings = () => {
  const { user, logout } = useUser();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // State for language, dark mode, data sync, and data deletion
  const [language, setLanguage] = useState(() => {
    const storedLanguage = localStorage.getItem('language');
    return storedLanguage || i18n.language || navigator.language.split('-')[0];
  });
  const [darkMode, setDarkMode] = useState('system');
  const [isSyncEnabled, setIsSyncEnabled] = useState(true);
  const [isBugReportOpen, setIsBugReportOpen] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  useEffect(() => {
    const savedMode = localStorage.getItem('themeMode');
    if (savedMode) {
      setDarkMode(savedMode);
    } else {
      setDarkMode('system');
    }

    const storedSyncSetting = localStorage.getItem('syncEnabled');
    setIsSyncEnabled(storedSyncSetting !== 'false'); // Default to true if not set
  }, []);

  const applyTheme = (mode) => {
    if (mode === 'dark') {
      document.body.classList.add('dark-mode');
    } else if (mode === 'light') {
      document.body.classList.remove('dark-mode');
    } else if (mode === 'system') {
      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      if (prefersDark) {
        document.body.classList.add('dark-mode');
      } else {
        document.body.classList.remove('dark-mode');
      }
    }
  };

  useEffect(() => {
    applyTheme(darkMode);
    if (darkMode !== 'system') {
      localStorage.setItem('themeMode', darkMode);
    }
  }, [darkMode]);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setLanguage(lang);
    localStorage.setItem('language', lang);
    toast.success(t('languageChanged'));
  };

  const changeMode = (mode) => {
    setDarkMode(mode);
    applyTheme(mode);
    if (mode !== 'system') {
      localStorage.setItem('themeMode', mode);
      toast.success(t('modeChanged'));
    } else {
      localStorage.removeItem('themeMode');
    }
  };

  const toggleSync = () => {
    const newSyncSetting = !isSyncEnabled;
    setIsSyncEnabled(newSyncSetting);
    localStorage.setItem('syncEnabled', newSyncSetting);
    toast.success(newSyncSetting ? t('syncEnabled') : t('syncDisabled'));
  };

  const handleDataDeletion = async () => {
    if (window.confirm(t('confirmDeleteData'))) {
      await clearMealsFromDB(user.userId); // Clear data from IndexedDB
      toast.success(t('dataDeleted'));
    }
  };
const native = Capacitor.isNativePlatform();
  return (
    <div className="panel">
      <div className="overlay"></div>
      <div className="modal">
        <h1>{t('settings')}</h1>
        <h2>{t('appearance')}</h2>
        <p>{t('changeLanguage')}</p>
        <select value={language} onChange={(e) => changeLanguage(e.target.value)}>
          <option value="en">English</option>
          <option value="pl">Polski</option>
          <option value="fr">Français</option>
        </select>

        <p>{t('defaultTheme')}</p>
        <select value={darkMode} onChange={(e) => changeMode(e.target.value)}>
          <option value="light">{t('lightMode')}</option>
          <option value="dark">{t('darkMode')}</option>
          <option value="system">{t('systemMode')}</option>
        </select>

    
        
        {native && (
        <>
            <h2>{t('dataSettings')}</h2>
          <p>{t('syncData')}</p>
          <label className="switch">
            <input type="checkbox" checked={isSyncEnabled} onChange={toggleSync} />
            <span className="slider round"></span>
          </label>
          <button className="buttonlogout" onClick={handleDataDeletion}>
            {t('deleteData')}
          </button>
        </>
      )}

        {user ? (
          <div>
            <h2>{t('user')}</h2>
            <p>{t('logoutText')}</p>
            <button className="buttonlogout" onClick={logout}>
              {t('logout')}
            </button>
          </div>
        ) : null}

        <hr />
        <p>{t('otherLinks')}</p>
        <Link to="/policy">
          <p>{t('privacyPolicyLink')}</p>
        </Link>
        <Link to="/terms">
          <p>{t('termsLink')}</p>
        </Link>

        <h2>{t('report_bug')}</h2>
        <button className="buttonlogout" onClick={() => setIsBugReportOpen(true)}>
          {t('report_bug')}
        </button>

       
      </div> {isBugReportOpen && (
          <BugReport 
            isOpen={isBugReportOpen} 
            onClose={() => setIsBugReportOpen(false)} 
             // Ustawienie nazwy produktu na null
       
          />
        )}
    </div>
  );
};

export default Settings;
