// src/Loading.js
import React, { useState, useEffect } from 'react'; // Dodaj useState i useEffect
import { NavLink } from 'react-router-dom';
import IonIcon from '@reacticons/ionicons';
import './Loading.css'; // Dodaj import CSS
import { useTranslation } from 'react-i18next';

const Loading = ({ isSmall }) => {
 const { t } = useTranslation();
  const icons = ["scan", "fast-food-outline", "calculator-outline", "pizza-outline"]; // Zbiór ikon
  const texts = [
    t("loading"),
    t("loading2"),
    t("loading3"),
    t("loading4"),
    t("loading5"),
    t("loading6"),
    t("loading7"),
    t("loading8"),
    t("loading9"),
    t("loading10"),
    t("loading11"),
  ]; // Zbiór tekstów
  const [iconIndex, setIconIndex] = useState(0); // Indeks ikony
  const [displayedText, setDisplayedText] = useState(""); // Tekst do wyświetlenia
 

  useEffect(() => {
    const interval = setInterval(() => {
      setIconIndex(prevIndex => (prevIndex + 1) % icons.length); // Przełączanie ikon i tekstów
    }, 4000); // Zmiana co 4 sekundy

    return () => clearInterval(interval); // Czyszczenie interwału
  }, []);

  useEffect(() => {
    let currentText = "";
    let charIndex = 0;

    const typingInterval = setInterval(() => {
      if (charIndex < texts[iconIndex].length) {
        currentText += texts[iconIndex][charIndex];
        setDisplayedText(currentText);
        charIndex++;
      } else {
        clearInterval(typingInterval);
      }
    }, 100); // Czas między literami

    return () => clearInterval(typingInterval); // Czyszczenie interwału
  }, [iconIndex]);

  return (
    <div className={isSmall ? "loading-container-small" : "loading-container"}>
      <div className="loading-icon">
        <IonIcon name={icons[iconIndex]} size={50} className="spin" />
      </div>
      <h2>{displayedText}</h2>
    </div>
  );
};

export default Loading;