import React, { useState, useEffect } from 'react';
import './App.css';
import axios from 'axios'; // Import Axios
import { useUser } from './UserContext';
import { getApiUrl } from './apiUrl';
import {Loading} from './Loading';
const InternetStatus = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [connectionType, setConnectionType] = useState(navigator.connection ? navigator.connection.effectiveType : 'unknown');
  const [showStatus, setShowStatus] = useState(false); // State to control the visibility of the status message
  const [isVerified, setIsVerified] = useState(false); // State to track email verification status
  const { user } = useUser();
  const apiUrl = getApiUrl();
  const [error, setError] = useState(null); // Stan do przechowywania błędu
  useEffect(() => { // Poprawione użycie useEffect
    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };
    checkServerAvailability(); // Wywołanie funkcji sprawdzającej dostępność serwera
    const updateConnectionStatus = () => {
      if (navigator.connection) {
        setConnectionType(navigator.connection.effectiveType);
      }
    };

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);
    if (navigator.connection) {
      navigator.connection.addEventListener('change', updateConnectionStatus);
    }

    // Cleanup functions
    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
      if (navigator.connection) {
        navigator.connection.removeEventListener('change', updateConnectionStatus);
      }
    };
  }, []); // Dodano pustą tablicę zależności

  useEffect(() => {
    // Initial check of verification status if user exists
    if (user) {
      checkVerification();
    } else {
      setIsVerified(true); // Assume verified if user is not present
    }

    // Set interval to check verification status every 10 seconds if user exists
    let interval;
    if (user) {
      interval = setInterval(() => {
        checkVerification();
        checkServerAvailability();
      }, 10000);
    }

    return () => {
      if (interval) {
        clearInterval(interval); // Cleanup interval on component unmount or user change
      }
    };
  }, [user]);
  // Dodaj funkcję do sprawdzania dostępności serwera
  const checkServerAvailability = async () => {
    try {
      const response = await fetch(getApiUrl()); // Użyj odpowiedniego URL
      if (!response.ok || !response) {
        throw new Error('Server not reachable');
      }
      setError(null); // Resetuj błąd, jeśli serwer jest dostępny
    } catch (error) {
      setError(error.message); // Ustaw błąd
    }
  };

  
  const checkVerification = async () => {
    if (!user) {
      setIsVerified(false); // Jeśli użytkownik nie jest zalogowany, ustawiamy isVerified na false
      return;
    }
  
    try {
      const userId = user.userId;
      const token = user.token;
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      };
  
      const response = await axios.get(`${apiUrl}checkVerify/${userId}`, {
        headers: headers
      });
  
      const data = response.data;
  
      if (response.status === 200) {
        setIsVerified(data.isVerify);
      
      } else {
        console.error(`Error checking verification status: ${data.message}`);
        setIsVerified(false); // Ustawienie isVerified na false w przypadku niepowodzenia
      }
    } catch (error) {
      console.error(`Error checking verification status: ${error.message}`);

    }
  };
  
  const isConnectionWeak = ['2g', 'slow-2g' , '3g', 'slow-3g'].includes(connectionType);

  const handleClose = () => {
    setShowStatus(false);
  };

  return (
    <>
      {error && ( // Dodaj komunikat o błędzie
         <div className="error-message">
         <p><strong>Serwer jest obecnie niedostępny. Proszę poczekać chwilę </strong></p>
         <button className="close-button" onClick={handleClose}>
           X
         </button>
       </div>
      )}
      {showStatus && (
        <div className="internet-status">
          <p><strong>Z powodu ulepszania AI dzisiaj następuje krótka przerwa modernizacyjna w działaniu tej funkcji. Po tym okresie AI znowu będzie działać. Poinformujemy gdy to się stanie. </strong></p>
          <button className="close-button" onClick={handleClose}>
            X
          </button>
        </div>
      )}
      {!isOnline && (
        <div className="internet-status">
          <p><strong>Jesteś obecnie offline. Niektóre funkcje mogą być niedostępne.</strong></p>
        </div>
      )}
      {isConnectionWeak && isOnline && (
        <div className="internet-status weak-connection">
          <p><strong>Twoje połączenie jest słabe. Możesz doświadczać opóźnień.</strong></p>
        </div>
      )}
      {!isConnectionWeak && isOnline && !isVerified && !error && ( // Dodano operator &&
        <div className="internet-status">
          <p><strong>Twój email nie jest zweryfikowany. Proszę zweryfikuj swój email, aby uzyskać pełen dostęp.</strong></p>
        </div>
      )}
    </>
  );
};

export default InternetStatus;
