// Scanner.js

import React, { useRef, useEffect, useState, useCallback } from 'react';
import Webcam from 'react-webcam';
import Quagga from 'quagga';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useUser } from './UserContext';
import { useNavigate } from 'react-router-dom';
import './Scanner.css';
import IonIcon from '@reacticons/ionicons';
import ReactGA from 'react-ga4';
import scanningSoundGoodFile from './scansoundgood.mp3';
import scanningSoundBadFile from './scansoundbad.mp3';
import scanningSoundDetectedFile from './scansounddetected.mp3';
import CameraInterface from './CameraInterface';
import { getApiUrl } from './apiUrl';
import Loading from './Loading';

import { useTranslation } from 'react-i18next';
import AddMealPPopup from './AddToMealPopup';
import BugReport from './BugReport';

const videoConstraints = {
  width: { min: window.innerHeight },
  height: { min: window.innerWidth },
  facingMode: 'environment', // Use 'environment' for rear camera, 'user' for front camera
};

const calculateFPU = (protein,fat) => {
  let multipliedProtein = protein * 4;
  let multipliedFat = fat * 9;
  return parseFloat((multipliedProtein + multipliedFat) / 100).toFixed(1);
};

const calculateCU = (carbohydrates) => {
  return carbohydrates ? parseFloat((carbohydrates / 10).toFixed(1)) : null;
};

const calculatePerServing = (per100g, servingSize) => {
  const servingSizeInGrams = parseFloat(servingSize);
  if (!isNaN(servingSizeInGrams) && per100g !== null) {
    return (per100g * servingSizeInGrams) / 100;
  }
  return null;
};

const formatNumber = (number) => {
  return typeof number === 'number' && isFinite(number) ? number.toFixed(1) : 'Brak informacji';
};

const ProductPopup = ({ product, onClose, onAddToMealClick }) => {
  const { t } = useTranslation();
  const [popupState, setPopupState] = useState({
    isOpen: false,
    isProcessing: false
  });
  const {user} = useUser();
  const navigate = useNavigate();

  // Pojedyncza funkcja do zarządzania stanem popupu
  const togglePopup = useCallback((shouldOpen) => {
    if (!user) {
      toast.error(t('mustBeLoggedIn'));
      navigate('/app/login');
      return;
    }
    if (popupState.isProcessing || popupState.isOpen === shouldOpen) return;

    setPopupState(prev => ({
      isOpen: shouldOpen,
      isProcessing: true
    }));

    setTimeout(() => {
      setPopupState(prev => ({
        ...prev,
        isProcessing: false
      }));
    }, 300);
  }, [popupState.isProcessing, popupState.isOpen]);

  const handleAddToMeal = useCallback((grams) => {
    console.log(`handleAddToMeal called with grams: ${grams}`);
    console.log(`Product: ${product.product_name}`);
    togglePopup(false);
  }, [product, togglePopup]);

  return (
    <div className="product-popup">
      <button className="close-button" onClick={onClose}>
        X
      </button>
      <h2>{product.product_name || product.name}</h2>
      <img src={product.image_url} alt={product.product_name} />

      <div className="social-media">
        <button className="search-link" onClick={onAddToMealClick}>
          <IonIcon name="bookmark" />
        </button>
        <button 
          className='search-link' 
          onClick={() => togglePopup(true)}
          disabled={popupState.isOpen || popupState.isProcessing}
        >
          <IonIcon name="restaurant" />
        </button>
      </div>

      {popupState.isOpen && 
        <AddMealPPopup
          isVisible={true}
          onClose={() => togglePopup(false)}
          addProductToMeal={handleAddToMeal}
          user={user}
          product={product}
        />
      }

      {product.is_gluten_free !== undefined ? (
        product.is_gluten_free ? 
        <p className='text-search-info'>{t('glutenFree')} <IonIcon name='checkmark-circle-outline' className='text-search' /></p> : 
        <p className='text-search-info'>{t('containsGluten')} <IonIcon name='close-circle-outline' className='text-search' /></p>
      ) : (
        <p></p>
      )}

      <p>{product.info || ''}</p> 
      <p className='text-search-info'>
        <IonIcon name='fast-food-outline' className='text-search' />
        {t('energy')} 
        {product.nutriments?.["energy-kcal"] ? `${product.nutriments["energy-kcal"]} kcal` : '0'} / 
        {product.nutriments?.["energy-kj"] ? `${product.nutriments["energy-kj"]} kj` : '0'}
      </p>

      <table>
        <thead>
          <tr>
            <th>{t('nutritionalValue')}</th>
            <th>{t('per100g')}</th>
            <th>{t('perServing')} {product.portion || ''}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{t('carbohydrates')}</td>
            <td>{formatNumber(product.carbohydrates_per100 || product.nutriments?.carbohydrates_100g)}</td>
            <td>{formatNumber(calculatePerServing(product.carbohydrates_per100 || product.nutriments?.carbohydrates_100g, product.serving_size || product.portion))}</td>
          </tr>
          <tr>
            <td>{t('protein')}</td>
            <td>{formatNumber(product.protein_per100 || product.nutriments?.proteins_100g)}</td>
            <td>{formatNumber(calculatePerServing(product.protein_per100 || product.nutriments?.proteins_100g, product.serving_size || product.portion))}</td>
          </tr>
          <tr>
            <td>{t('fat')}</td>
            <td>{formatNumber(product.fat_per100 || product.nutriments?.fats_100g)}</td>
            <td>{formatNumber(calculatePerServing(product.fat_per100 || product.nutriments?.fats_100g, product.serving_size || product.portion))}</td>
          </tr>
          {/* Add other nutritional information */}
          <tr>
            <td> <IonIcon name='flash' className='text' size={20} />{t('WW')}</td>
            <td>{calculateCU(product.carbohydrates_per100 || product.nutriments?.carbohydrates_100g)}</td>
            <td>{calculateCU(calculatePerServing(product.carbohydrates_per100 || product.nutriments?.carbohydrates_100g, product.serving_size || product.portion))}</td>
          </tr>
          <tr>
            <td><IonIcon name='flash-outline' className='text-search' />{t('fpu')}</td>
            <td>{calculateFPU(product.nutriments?.proteins, product.nutriments?.fat)}</td>
            <td>{calculateFPU(calculatePerServing(product.nutriments?.proteins, product.serving_size), calculatePerServing(product.nutriments?.fat, product.serving_size))}</td>
          </tr>
        </tbody>
      </table> 
    </div>
  );
};

const Scanner = () => {
  const webcamRef = useRef(null);
  const [product, setProduct] = useState(null);
  const [showProductPopup, setShowProductPopup] = useState(false);
  const { t } = useTranslation();
  const [scanningSoundGood, setScanningSoundGood] = useState(new Audio(scanningSoundGoodFile));
  const [scanningSoundBad, setScanningSoundBad] = useState(new Audio(scanningSoundBadFile));
  const [scanningSoundDetected, setScanningSoundDetected] = useState(new Audio(scanningSoundDetectedFile));
  const [isScanningEnabled, setScanningEnabled] = useState(true);
  const [loading, setLoading] = useState(false); // Dodano stan ładowania
  const [serverAvailable, setServerAvailable] = useState(true);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showBugReport, setShowBugReport] = useState(false);
  const [scannedCode, setScannedCode] = useState(null);

  const checkServerAvailability = async () => {
    setLoading(true); // Ustawienie ładowania na true
    try {
      const response = await fetch(getApiUrl()); // Użyj odpowiedniego URL
      if (!response.ok) {
        throw new Error('Server not reachable');
      }
      setServerAvailable(true); // Ustawienie dostępności serwera na true
    } catch (error) {
      setServerAvailable(false); // Ustawienie dostępności serwera na false
    } finally {
      setLoading(false); // Ustawienie ładowania na false
    }
  };

  useEffect(() => {
    checkServerAvailability();
  
    const intervalId = setInterval(() => {
      checkServerAvailability();
    }, 5000); // Sprawdzenie dostępności serwera co 5 sekund

    return () => clearInterval(intervalId); // Czyszczenie interwału po odmontowaniu komponentu
  }, []);

  const InitializeScanner = () => {
    // Use Webcam for web platforms
    Quagga.init(
      {
        inputStream: {
          type: 'LiveStream',
          target: document.querySelector('#target'),
          constraints: videoConstraints,
        },
        locator: {
          patchSize: 'medium',
          halfSample: true,
        },
        numOfWorkers: 2,
        decoder: {
          readers: [
            'code_128_reader',
            'ean_reader',
            'ean_8_reader',
            'code_39_reader',
            'code_39_vin_reader',
            'codabar_reader',
            'upc_reader',
            'upc_e_reader',
            'i2of5_reader',
            '2of5_reader',
            'code_93_reader',
          ],
        },
      },
      (err) => {
        if (err) {
          console.error(err);
          toast.error(t('scannerInitializationError'));
          return;
        }

        console.log('Initialization finished. Ready to start');

        Quagga.onDetected((result) => {
          const code = result.codeResult.code;
          handleBarcodeDetected(code);
        });

        Quagga.start();

        return () => {
          Quagga.offDetected(handleBarcodeDetected);
        };
      }
    );
  };

  const [errorMessage, setErrorMessage] = useState('');

  const handleBarcodeDetected = async (code) => {
    const apiUrl = getApiUrl();
   
    setErrorMessage('');
    scanningSoundDetected.play();
 
    try {
      if (isScanningEnabled === true) {
        const response = await axios.get(`${apiUrl}scan?barcode=${code}`, {
          withCredentials: true, // Ensure CORS is handled
        });

        if (response.status === 200) {
          if (response.data.success) {
            setErrorMessage('');
            if (product?.name !== response.data.product?.name) {
              if (product?.name === response.data.product?.name) return;
              scanningSoundGood.play();
              ReactGA.event({
                category: 'app_use',
                action: 'scan-good',
                label: 'Succesfully get info from scan',
              });
              setProduct(response.data.product);
              setShowProductPopup(true);
              setScanningEnabled(false);
            }
          } else {
            setErrorMessage(response.data.message);
            scanningSoundBad.play();
          }
        } else {
          // Handle cases where the request was successful but the product was not found
          if (response.status === 404) {
            // Check the server's response for more details
            if (response.data && response.data.message) {
              return toast.error(response.data.message, { autoClose: 5000 });
            } else {
              setTimeout(() => {
              if(product === null || !product){
                    scanningSoundBad.play();
                    ReactGA.event({
                      category: 'app_use',
                      action: 'scan-bad',
                      label: ' Code not found',
                    });
              }
            },1000);
              return toast.error(t('productNotFound'), { autoClose: 5000 });

            }
          } else {
            // Handle other non-404 errors
            ReactGA.event({
              category: 'app_use',
              action: 'scan-bad-other',
              label: 'Other error',
            });
            scanningSoundBad.play();
            toast.error(t('barcodeScanningError'));
          }
        }
      }
    } catch (error) {
      // Check if the error is a 404 response and avoid logging to the console
      setTimeout(() => {
        if (error.response && error.response.status === 404) {
       
          if(product === null || !product){
                scanningSoundBad.play();
                ReactGA.event({
                  category: 'app_use',
                  action: 'scan-bad',
                  label: ' Code not found',
                });;
                const product = {
                  product_name: code,
                  // Możesz ustawić domyślną nazwę
                  nutriments: {
                      carbohydrates_per100: 0,
                      proteins_per100: 0,
                      fats_per100: 0,
                  },
              };
                setScannedCode(product);
          }
       
        return setErrorMessage(t('codeOrProductNotFound'));
      } else {
        // Handle other errors and avoid logging to the console
        toast.error(t('barcodeScanningError'));
      }
      },1000);
      
    }
  };

  const closeProduct = () => {
    setProduct(null);
    setShowProductPopup(false);
    setScanningEnabled(true);
    Quagga.start();
  };

  const addToMeal = async (user, product, navigate,toast,t) => {
    // Check if the user is logged in
    ReactGA.event({
      category: 'app_use',
      action: 'addmeal',
      label: ' Added to meal',
    });
    const apiUrl = getApiUrl();
    if (!user) {
      toast.error(t('mustBeLoggedInToAddProduct'));
      navigate('/app/login');
      return;
    }
  
    try {
      // Generate a unique ID for the product
      const productId = generateRandomNumber();
  
      // Make a POST request to add the product to the meal
      const response = await axios.post(
      `${apiUrl}addProductToList`,
        {
          userId: user.userId,
          productId: productId,
          productJson: JSON.stringify({
            // Include relevant product details
            product_name: product.product_name || product.name,
            barcode: product.barcode || '',
            nutriments: {
              carbohydrates_100g: product.carbohydrates_per100 || product.nutriments?.carbohydrates_100g || product.nutriments?.carbohydrates || 0,
              proteins_100g: product.protein_per100 || product.nutriments?.proteins_100g || product.nutriments?.proteins || 0,
              fats_100g: product.fat_per100 || product.nutriments?.fats_100g || product.nutriments?.fat ||0,
            },
          }),
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`, // Pass JWT as Authorization header
          },
          withCredentials: true, // Ensure proper handling of credentials
        }
      );
  
      const { success, message } = response.data;
  
      if (success) {
        toast.success(`${t('saved')} ${product.product_name || product.name} ${t('saved2')}`);
        
      } else {
        toast.error(`${t('errorOccurred')}: ${message}`);
      }
    } catch (error) {
      console.error(error);
      toast.error(t('errorSaving'));
    }
  };
  
  const onAddToMealClick = (user,product,navigate) => {
    addToMeal(user, product,navigate,toast,t)
  };

  useEffect(() => {
    InitializeScanner();
  }, []);
  
  const generateRandomNumber = () => Math.floor(Math.random() * 1000000);
  const { user } = useUser();
  const navigate = useNavigate();

  return (
    <div className="panel">
      <div className="overlay"></div>
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        {serverAvailable ? ( // Sprawdzenie dostępności serwera
          <>
           <h1 className='blue scannertxt'>{t('scanner')}</h1>
            <Webcam
              id="target"
              className='target'
              ref={webcamRef}
              audio={false}
              videoConstraints={videoConstraints}
              style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
            />
            <div className="scanning-frame"></div>
            <CameraInterface />
           
            {errorMessage && (
              <div className='errormessage'>
                {errorMessage}
                <button 
                  className="report-missing-btn"
                  onClick={() => {
                    setShowBugReport(true);
                    setScannedCode(scannedCode);
                  }}
                >
                  {t('report_missing_product')}
                </button>
              </div>
            )}
          </>
        ) : (
          <Loading/> // Wyświetlenie komponentu Loading, gdy serwer jest niedostępny
        )}
      </div>
      {showProductPopup && (
              <ProductPopup className="pop-up" product={product} onClose={closeProduct} onAddToMealClick={() => addToMeal(user, product, navigate, toast, t)} />
            )}
      {showBugReport && (
        <BugReport 
          isOpen={showBugReport}
          onClose={() => setShowBugReport(false)}
          scannedBarcode={scannedCode}
          isScanning={true}
        />
      )}
    </div>
  );
}

export default Scanner;