import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';

const MealContext = createContext();

export const useMeal = () => {
  return useContext(MealContext);
};

export const MealProvider = ({ children }) => {
  const [currentMeal, setCurrentMeal] = useState(() => {
    try {
      const saved = localStorage.getItem('currentMeal');
      console.log('Initial load of currentMeal:', saved);
      if (saved) {
        const parsed = JSON.parse(saved);
        return {
          ...parsed,
          id: parseInt(parsed.id)
        };
      }
      return null;
    } catch (error) {
      console.error('Error loading currentMeal:', error);
      localStorage.removeItem('currentMeal');
      return null;
    }
  });

  const updateCurrentMeal = useCallback((mealData) => {
    try {
      if (mealData) {
        const mealToSave = {
          ...mealData,
          id: parseInt(mealData.id)
        };
        console.log('Saving meal to localStorage:', mealToSave);
        localStorage.setItem('currentMeal', JSON.stringify(mealToSave));
        setCurrentMeal(mealToSave);
      }
      // Usuwamy else block, żeby nie czyścić localStorage gdy mealData jest null
    } catch (error) {
      console.error('Error updating currentMeal:', error);
    }
  }, []);

  const clearCurrentMeal = () => {
    setCurrentMeal(null);
    localStorage.removeItem('currentMeal');
  };

  return (
    <MealContext.Provider value={{ 
      currentMeal, 
      setCurrentMeal: updateCurrentMeal,
      clearCurrentMeal 
    }}>
      {children}
    </MealContext.Provider>
  );
};
