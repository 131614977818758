// Media.js
import React from 'react';
import { useTranslation } from 'react-i18next'; 
import { Link } from 'react-router-dom';
import './Details.css';
import logo23lo from './files/logo_23lo.webp';
import logoAperto from './files/aperto.png';
const Partners = () => {
  const { t } = useTranslation();

  return (
    <section className="media-container partners-container fade-in">
     
      <h2 className="media-title partners"> {t('partnersdesc')}</h2>
      <p className="details-vc-description">{t('partnersdc')}</p>

      <div className="media-links">
        <div className="media-card honorary-partner">
          <Link to="https://xxiiilo.krakow.pl/" className="media-link">
            <img src={logo23lo} alt="23 LO" className="partner-logo-large" />
            <div className="media-text"> 
               <p className="honorary-title">{t('honortitle')}</p>
              <h3>Dyrekcja i Samorząd Uczniowski XXIII Liceum Im. Płk. Pil. Stanisława Skarżyńskiego w Krakowie</h3>
            
              
            </div>
          </Link>
        </div>
        <div className="media-card honorary-partner">
          <Link to="https://apertostrefa.pl/" className="media-link">
            <img src={logoAperto} alt="23 LO" className="partner-logo-large" />
            <div className="media-text"> 
               <p className="honorary-title">{t('honortitle')}</p>
              <h3>Stowarzyszenie Aperto Strefa</h3>
            
              
            </div>
          </Link>
        </div>
        <div className="media-card">
          <Link to="https://autopay.pl/" className="media-link">
            <div className="media-image autopay"></div>
            <div className="media-text">Autopay - UsuwaMY Bariery!</div>
          </Link>
        </div>
        <div className="media-card">
          <Link to="https://startup.google.com/" className="media-link">
            <div className="media-image google"></div>
            <div className="media-text">Google For Startups</div>
          </Link>
        </div>
        <div className="media-card">
          <Link to="https://www.enea.pl/" className="media-link">
            <div className="media-image enea"></div>
            <div className="media-text">Enea - Energia w Nauce</div>
          </Link>
        </div>
        <div className="media-card">
          <Link to="https://3mamcukier.pl/" className="media-link">
            <div className="media-image trzymamcukier"></div>
            <div className="media-text">Blog 3mamcukier.pl</div>
          </Link>
        </div>
        {/* Add more media cards here if needed */}
      </div>
      <hr></hr>
    </section>
  );
};

export default Partners;