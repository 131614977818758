// apiUrl.js
import { Capacitor } from '@capacitor/core';

export function getApiUrl() {
    const IS_ENV_DEVELOPMENT = window.location.hostname.includes('localhost');
   
    let apiUrl = 'https://api.diabcalc.com/';
  
    if (IS_ENV_DEVELOPMENT) {
      apiUrl = 'http://localhost:3001/';
     //apiUrl = 'https://api.diabcalc.com/';
    }
  
    if(Capacitor.isNativePlatform()){
      apiUrl = 'https://api.diabcalc.com/';
    }
    return apiUrl;
  }
  