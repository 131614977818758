import React, { useState, useEffect } from 'react';
import './BugReport.css';
import './AddProductMealPopup.css';
import { getApiUrl } from './apiUrl';
import { useTranslation } from 'react-i18next';

const BugReport = ({ isOpen, onClose, scannedBarcode, isScanning }) => {
    const { t } = useTranslation();
    const apiUrl =  getApiUrl();
  const [bugReport, setBugReport] = useState({
    description: '',
    product: null,
    errortype: 1
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  useEffect(() => {
    if (scannedBarcode && scannedBarcode.product_name) {
      setBugReport((prev) => ({ ...prev, product: scannedBarcode?.product_name }));
    }
  }, [scannedBarcode]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    try {
      const response = await fetch(`${apiUrl}reportError`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          ...bugReport,
      
        })
      });

      const data = await response.json();

      if (response.ok) {
        setSubmitStatus({ type: 'success', message: 'Zgłoszenie zostało wysłane pomyślnie!' });
        setTimeout(() => {
          onClose();
          setBugReport({ description: '', product_name: '', errortype: 'technical' });
          setSubmitStatus(null);
        }, 2000);
      } else {
        setSubmitStatus({ type: 'error', message: data.message || 'Wystąpił błąd podczas wysyłania zgłoszenia.' });
      }
    } catch (error) {
      setSubmitStatus({ type: 'error', message: 'Nie udało się połączyć z serwerem.' });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {isOpen && (
        <div className="popup-overlay pop-up">
          <div className="popup-container">
            <h3>{t('report_bug')}</h3>
            <div className="scroll">
              <form  onSubmit={handleSubmit}>
                {(bugReport.errortype === 1 || bugReport.errortype === 2) && (
                  <div className="">
                    <label>{t('product_name')}:</label>
                    <input
                      type="text"
                      value={bugReport.product || ''}
                      onChange={(e) => setBugReport({...bugReport, product: e.target.value})}
                      required
                      disabled={isScanning}
                      placeholder={isScanning ? t('scanning_barcode') : 'Wpisz nazwę produktu'}
                    />
                  </div>
                )}
             

                <div className="form-group">
                  <label>{t('error_type')}:</label>
                  <select
                    value={bugReport.errortype}
                    onChange={(e) => setBugReport({...bugReport, errortype: parseInt(e.target.value)})}
                    required
                  >
                    <option value="1">{t('technical')}</option>
                    <option value="2">{t('ui')}</option>
                    <option value="3">{t('functional')}</option>
                    <option value="4">{t('other')}</option>
                    <option value="5">{t('technical_error')}</option>
                  </select>
                </div>

                <div className="form-group">
                  <label>{t('error_description')}:</label>
                  <textarea
                    value={bugReport.description}
                    onChange={(e) => setBugReport({...bugReport, description: e.target.value})}
                    required
                    placeholder={t('describe_problem')}
                  />
                </div>

                <button 
                  type="submit" 
                  className="standard-button"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? t('sending') : t('send_report')}
                </button>

                {submitStatus && (
                  <div className={`status-message ${submitStatus.type}`}>
                    {submitStatus.message}
                  </div>
                )}
              </form>
            </div>
            <hr />
            <button 
              className="certificate-btn secondary" 
              onClick={onClose}
            >
              Zamknij
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default BugReport;