import React from 'react';
import IonIcon from '@reacticons/ionicons';
import './AddProductMealPopup.css';

const ShortcutPopup = ({ availableShortcuts, onAddShortcut, onClose }) => {
  return (
    <div className="popup-overlay pop-up">
      <div className="popup-container">
        <h3>Dodaj skrót</h3>
      <div className='scroll'>
          {availableShortcuts.map(shortcut => (
            <button 
              className='standard-button' 
              key={shortcut.id}
              onClick={() => onAddShortcut(shortcut)}
            >
              <IonIcon name={shortcut.icon} style={{ marginRight: '8px' }} />
              {shortcut.label}
            </button>
          ))}
        </div>
        <hr></hr>
        <button className="certificate-btn secondary" onClick={onClose}>Zamknij</button>
      </div>
    </div>
  );
};

export default ShortcutPopup;
