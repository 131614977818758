// ModeratorApplication.js
import React from 'react';
import { useTranslation } from 'react-i18next'; // Assuming you're using react-i18next for translations
import moderatorImage from './files/moderatorimg.jpeg'; // An image representing moderation
import './VideoCard.css'; // CSS file for styling

const ModeratorApplication = () => {
  const { t } = useTranslation();

  return (
    <section className="details-vc-container mod fade-in">
      <div className="mod-image-section">
        <img src={moderatorImage} className="mod-img" alt="Moderator" />
      </div>
      <div className="dc-vc-text-section">
        <div className="details-vc-title">
          <h1>{t('becomeModeratorTitle')}</h1>
        </div>
        <br />
        <p className="details-vc-description">{t('moderatorDescriptionLong')}</p>
        
        <h2 className='details-vc-description'>{t('moderatorBenefitsTitle')}</h2>
        <ul className="benefits-list">
          <li className='details-vc-description'>{t('moderatorBenefit1')}</li>
          <li className='details-vc-description'>{t('moderatorBenefit2')}</li>
          <li className='details-vc-description'>{t('moderatorBenefit3')}</li>
        </ul>

        <h2>{t('howToApply')}</h2>
        <p>{t('applicationInstructions')}</p>
        <a href="/benewmod" className="apply-button">{t('applyNow')}</a>
      </div>
      
    </section>
  );
};

export default ModeratorApplication;
