import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { getApiUrl } from './apiUrl';
import { useTranslation } from 'react-i18next';
import IonIcon from '@reacticons/ionicons';
import './SharedProductDetails.css'; // Import pliku CSS
import { Link } from 'react-router-dom';
import NavBar from './NavBar';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
const SharedProductDetails = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const calculateFPU = (protein, fat) => {
    let multipliedProtein = protein * 4;
    let multipliedFat = fat * 9;
    return parseFloat((multipliedProtein + multipliedFat) / 100).toFixed(1);
  };

  const handleOpenWebVersion = () => {
    navigate('/app');
  };

  const formatNumber = (number) => {
    return typeof number === 'number' && isFinite(number) ? number.toFixed(1) : 'Brak informacji';
  };

  const calculateCU = (carbohydrates) => {
    return carbohydrates ? parseFloat((carbohydrates / 10).toFixed(1)) : 'Brak informacji';
  };

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await fetch(`${getApiUrl()}getProductInlist/${productId}`);
        const data = await response.json();
        if (response.ok) {
          const parsedProduct = JSON.parse(data.product.json);
          setProduct(parsedProduct);
        } else {
          setError(data.message);
        }
      } catch (err) {
        setError('Wystąpił błąd podczas pobierania danych.');
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [productId]);

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return (
      <div className="error-container">
        <header className="shared-header">{t('sharedProduct')}</header>
        <div className="error-message">{error}</div>
        <footer className="shared-footer">© 2024 DiabCalc</footer>
      </div>
    );
  }

  return (
    <div className="product-container">
 <NavBar />
      <header className="shared-header">{t('sharedProduct')}</header>
      {product ? (
        <div className="product-details">
          <h1 className="product-name-shared">{product.product_name}</h1>
          <table className="product-table">
            <thead>
              <tr>
                <th>{t('nutritionalValue')}</th>
                <th>{t('per100g')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{t('carbohydrates')}</td>
                <td>{formatNumber(product.nutriments?.carbohydrates_100g)}</td>
              </tr>
              <tr>
                <td>{t('protein')}</td>
                <td>{formatNumber(product.nutriments?.proteins_100g)}</td>
              </tr>
              <tr>
                <td>{t('fat')}</td>
                <td>{formatNumber(product.nutriments?.fats_100g)}</td>
              </tr>
              <tr>
                <td><IonIcon name="flash" />{t('exchange')}</td>
                <td>{calculateCU(product.nutriments?.carbohydrates_100g)}</td>
              </tr>
              <tr>
                <td><IonIcon name="flash-outline" />{t('fpu')}</td>
                <td>{calculateFPU(product.nutriments?.proteins_100g, product.nutriments?.fats_100g)}</td>
              </tr>
            </tbody>
          </table>
          
     </div>    
         
  
       
      ) : (
        <div className="error-message">Product not found</div>
      )}

      {/* Komunikat section */}
      <div className="komunikat">
        <p>{t('registerForAccess')}</p>
        <div className='open-web-btn-shared-container'>
        <button className="open-web-btn-shared" onClick={handleOpenWebVersion}>
          <IonIcon name="globe-outline" className="icon" size={50} />
          {t('openweb')}<br />
          <div className='osicons'>
          <IonIcon name="logo-android"  className='osicon' />
          <IonIcon name="logo-apple"  className='osicon' />
          <IonIcon name="logo-microsoft"  className='osicon' />
          </div>
        </button>
        <br></br>
        
        <Link className="open-web-btn-shared" to="https://play.google.com/store/apps/details?id=com.koderteam.diabcalcnative" >
          <IonIcon name="logo-google-playstore" className="icon" size={50} />
          {t('openandroid')}<br />
          <div className='osicons'>
          <IonIcon name="logo-android"  className='osicon' />
      
          </div>
        </Link>
        </div>
      </div>

     <Footer />
    </div>
  );
};

export default SharedProductDetails;
