import React from 'react';
import './Policy.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NavBar from './NavBar';
import Footer from './Footer';

const Policy = () => {
  const { t } = useTranslation();

  return (
    <div className='landing-page policy'>
      <NavBar />

      <div className='landinginfo'>
        <h1>{t('privacyPolicy')}</h1>
        <hr />

        <p className='zakladkip'>{t('privacyIntro')}</p>

        <ol>
          <li>
            <strong>{t('dataController')}:</strong> {t('dataControllerDetails')}
          </li>
          <li>
            <strong>{t('processingPurpose')}:</strong> {t('processingPurposeDetails')}
          </li>
          <li>
            <strong>{t('dataTypes')}:</strong> {t('dataTypesDetails')}
          </li>
          <li>
            <strong>{t('dataSharing')}:</strong> {t('dataSharingDetails')}
          </li>
          <li>
            <strong>{t('dataRetentionPeriod')}:</strong> {t('dataRetentionPeriodDetails')}
          </li>
          <li>
            <strong>{t('userRights')}:</strong> {t('userRightsDetails')}
          </li>
          <li>
            <strong>{t('dataSecurity')}:</strong> {t('dataSecurityDetails')}
          </li>
          <li>
            <strong>{t('policyChanges')}:</strong> {t('policyChangesDetails')}
          </li>
          <li>
            <strong>{t('contactDetailsPolicy')}:</strong> <a href="mailto:diabcalcapp@gmail.com">diabcalcapp@gmail.com</a>
          </li>
        </ol>
      </div>

      <Footer />
    </div>
  );
};

export default Policy;
