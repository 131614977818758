import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./WelcomeScreen.css";
import IonIcon from '@reacticons/ionicons';
import { Preferences } from '@capacitor/preferences';  // Importujemy Preferences
import { Capacitor } from "@capacitor/core";

const WelcomeScreen = () => {
  const [showWelcome, setShowWelcome] = useState(true); // Change to true by default
  const [isClicked, setIsClicked] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkWelcomeStatus = async () => {
      let hasSeenWelcome;
      
      if (Capacitor.isNativePlatform()) {
        const { value } = await Preferences.get({ key: 'hasSeenWelcome' });
        hasSeenWelcome = value === 'true';
      } else {
        hasSeenWelcome = localStorage.getItem("hasSeenWelcome") === 'true';
      }
      
      setShowWelcome(!hasSeenWelcome);
    };

    checkWelcomeStatus();
  }, []);

  const handleNavigate = async () => {
    const currentValue = Capacitor.isNativePlatform()
      ? (await Preferences.get({ key: 'hasSeenWelcome' })).value
      : localStorage.getItem("hasSeenWelcome");

    if (currentValue !== 'true') {
      if (Capacitor.isNativePlatform()) {
        await Preferences.set({
          key: 'hasSeenWelcome',
          value: 'true',
        });
      } else {
        localStorage.setItem("hasSeenWelcome", "true");
      }
      window.location.reload();
    } else {
      navigate("/app");
    }
    
    setIsClicked(true);
    setIsHidden(true);
    setShowWelcome(false);
  };

  if (!showWelcome) return null;

  return (
    <div className={`welcome-container ${isHidden ? "fade-out" : "pop-up"}`} onClick={handleNavigate}>
      <div className={`welcome-card ${isClicked ? "lifted" : ""}`}>
        <h1 className="welcome-title blue">Witaj w naszej Aplikacji</h1>
        <p className="welcome-subtitle blue">Oto kilka rzeczy na start!</p>
        <div className="welcome-links">
          <div className="welcome-item">
            <IonIcon name="scan-outline" className="blueicon welcome-icon" />
            <p className="welcome-text">
              Zeskanuj kod kreskowy produktu i wylicz jego wartości odżywcze.
            </p>
          </div>
          <div className="welcome-item">
            <IonIcon name="image-outline" className="blueicon welcome-icon" />
            <p className="welcome-text">
              Uzyskaj dane na temat makroskładników w posiłku z samego jego zdjęcia.
            </p>
          </div>
          <div className="welcome-item">
            <IonIcon name="person-outline" className="blueicon welcome-icon" />
            <p className="welcome-text">
              Zaloguj się, aby uzyskać dostęp do funkcji posiłku i analizy z tekstu.
            </p>
          </div>
        </div>
        <button className="welcome-button">
          Przejdź do aplikacji
        </button>
      </div>
    </div>
  );
};

export default WelcomeScreen;
