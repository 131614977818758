import React, { useState } from 'react';
import axios from 'axios';
import NavBar from './NavBar';
import Footer from './Footer';
import './ModeratorApplicationForm.css';
import { useTranslation } from 'react-i18next';
import { getApiUrl } from './apiUrl';
import IonIcon from '@reacticons/ionicons';
const ModeratorApplicationForm = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    full_name: '',
    email: '',
    experience_with_diabcalc: '',
    availability: '',
    motivation: '',
    moderation_experience: '',
    experience_description: ''
  });

  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setResponseMessage('');
    setErrorMessage('');

    try {
      const token = localStorage.getItem('authToken');
      const apiUrl = `${getApiUrl()}createModeratorApplication`; // Use the getApiUrl function here
      const response = await axios.post(
        apiUrl,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      setResponseMessage(t('success_message'));
      setFormData({
        full_name: '',
        email: '',
        experience_with_diabcalc: '',
        availability: '',
        motivation: '',
        moderation_experience: '',
        experience_description: ''
      });
    } catch (error) {
      console.error(error);
      setErrorMessage(t('error_message'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='bg'>
      <div className="moderator-application-container-form">
        <NavBar />
        <div className="moderator-application-form">
          <h2 className="form-title">{t('form_title')} <IonIcon name='shield-outline'></IonIcon></h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="full_name">{t('full_name')}</label>
              <input
                type="text"
                id="full_name"
                name="full_name"
                value={formData.full_name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">{t('emailadress')}</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="experience_with_diabcalc">{t('experience_with_diabcalc')}</label>
              <input
                type="text"
                id="experience_with_diabcalc"
                name="experience_with_diabcalc"
                value={formData.experience_with_diabcalc}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="availability">{t('availability')}</label>
              <input
                type="text"
                id="availability"
                name="availability"
                value={formData.availability}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="motivation">{t('motivation')}</label>
              <textarea
                id="motivation"
                name="motivation"
                value={formData.motivation}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="moderation_experience">{t('moderation_experience')}</label>
              <input
                type="text"
                id="moderation_experience"
                name="moderation_experience"
                value={formData.moderation_experience}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="experience_description">{t('experience_description')}</label>
              <textarea
                id="experience_description"
                name="experience_description"
                value={formData.experience_description}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <button type="submit" className="submit-btn" disabled={loading}>
                {loading ? t('submitting') : t('submitadmin')}
              </button>
            </div>
          </form>
          {responseMessage && <p className="success-message">{responseMessage}</p>}
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ModeratorApplicationForm;
