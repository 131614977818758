import React from 'react';
import PropTypes from 'prop-types';
import AboutUs from './AboutUs';
import Header from './Header';
import Details from './Details';
import diabCalcImage from './logo.jpg';
import './LandingPage.css';
import IonIcon from "@reacticons/ionicons";
import Goals from './Ideas';
import NavBar from './NavBar';
import VideoCard from './VideoCard';
import Media from './Media';
import Partners from './Partnerzy';
import BeModerator from './ModeratorApplication';
import Influencers from './Influencers';
import Help from './Help';
import FAQ from "./FAQ";
import NewsletterSignup from './NewsletterSignup';
import PartnersBar from './PartnersBar';
const LandingInfo = ({ t, handleOpenWebVersion }) => {
  return (
    <div className='landinginfo'>
      {/* Add smooth scroll behavior for a modern feel */}
      <div id='header'>
      <Header handleOpenWebVersion={handleOpenWebVersion} />
      </div>
    
      <hr className="styled-hr" />

      {/* Details Section */}
      <div id="details-section" className="section details">
        <Details />
      </div>

      <hr className="styled-hr" />

      {/* Goals Section with subtle fade-in animation */}
      <div id='goals-section' className="section goals">
        <Goals />
      </div>

      <hr />

      
     <div id='partners-section'>
        <Partners />
      </div>
 <div id='help'>
       <Help/>
      </div> 
 
<hr/>
     
       <div id='media-section'>
        <Media />
      </div> 
        <hr className="styled-hr" />
<div id='influ-section'>
<Influencers/>
</div>
<hr></hr>
<div id="newsletter-section">
      <NewsletterSignup />
      </div>
      <hr></hr>
      {/* Video Section */}
      <div id="video-section" className="section video">
        <VideoCard />
      </div>
<hr></hr>
      {/* Media Section */}
        <hr></hr>
   <div id='moderation-section'>
       <BeModerator/>
      </div>
 
<hr/>
      {/* About Section */}
      <div id="about-section" className="section about">
        <AboutUs />
      </div> 
      <div id='faq'>
      <FAQ />
      </div>
    </div>
  );
};

LandingInfo.propTypes = {
  t: PropTypes.func.isRequired,
  handleOpenWebVersion: PropTypes.func.isRequired,
};

export default LandingInfo;
