import React, { useEffect } from 'react';
import Draggable from 'react-draggable';
import IonIcon from '@reacticons/ionicons';
import { Link } from 'react-router-dom';

const gridSize = 100;

const Shortcuts = ({ shortcuts, setShortcuts, isEditing }) => {

  useEffect(() => {
    localStorage.setItem('shortcuts', JSON.stringify(shortcuts));
  }, [shortcuts]);

  const handleDragStop = (e, data, shortcutId) => {
    const gridY = Math.round(data.y / gridSize);

    // Check if new position overlaps with any existing shortcuts
    const isPositionTaken = shortcuts.some(
      shortcut => shortcut.id !== shortcutId && shortcut.position.y === gridY
    );

    if (!isPositionTaken) {
      const updatedShortcuts = shortcuts.map(shortcut => 
        shortcut.id === shortcutId 
          ? { ...shortcut, position: { x: shortcut.position.x, y: gridY } }
          : shortcut
      );
      setShortcuts(updatedShortcuts);
    } else {
      console.log('This position is already taken');
    }
  };

  const handleDelete = (shortcutId) => {
    const updatedShortcuts = shortcuts.filter(shortcut => shortcut.id !== shortcutId);
    setShortcuts(updatedShortcuts);
  };

  return (
    <div className="shortcuts-container">
      {shortcuts.map((shortcut) => (
        <Draggable
          key={shortcut.id}
          position={{ x: 0, y: shortcut.position.y * gridSize }}
          grid={[gridSize, gridSize]}
          disabled={!isEditing}
          onStop={(e, data) => handleDragStop(e, data, shortcut.id)}
          cancel='.delete-btn'
        >
          <div 
            className={`shortcut-button ${isEditing ? 'editing' : ''}`} 
            style={{ backgroundColor: shortcut.color || 'var(--ui_background)' }}
          >
            {isEditing ? (
              <>
              <p className="shortcut-label">{shortcut.label}</p>
                <IonIcon name={shortcut.icon} className="shortcut-icon" />
                <button 
                  onClick={() => handleDelete(shortcut.id)} 
                  className='delete-btn'
                  aria-label="Delete shortcut"
                >
                  &times;
                </button>
                {/* Add controls for customization */}
              </>
            ) : (
              <Link 
                to={shortcut.link} 
                className="shortcut-link"
              >    
                <p className="shortcut-label">{shortcut.label}</p>
                <IonIcon name={shortcut.icon} className="shortcut-icon" />
              </Link>
            )}
          </div>
        </Draggable>
      ))}
    </div>
  );
};

export default Shortcuts;
